import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core";
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="popper"
export default class extends Controller {
  static targets = ["element", "tooltip"];
  static values = {
    placement: { type: String, default: "top" },
    offset: { type: Array, default: [0, 8] },
    active: false,
  };

  connect() {
    useClickOutside(this)
    // Create a new Popper instance
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.tooltipTarget?.dataset?.placement || this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue,
          },
        },
      ],
    });
  }

  close(event) {
    this.hide(event)
  }

  show(event) {
    if (this.active) {
      this.hide(event);
      return;
    }
    this.tooltipTarget.setAttribute("data-show", "");
    this.active = true;
    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    this.popperInstance.update();
  }

  hide(event) {
    this.active = false;
    this.tooltipTarget.removeAttribute("data-show");
  }

  // Destroy the Popper instance
  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
