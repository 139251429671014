import slimSelect from 'slim-select'
;(function () {
  const initSlimSelect = (elementId, placeholder = 'Selecione um valor') => {
    if (!!elementId) {
      return new slimSelect({
        select: `#${elementId}`,
        placeholder: placeholder,
        searchPlaceholder: 'Pesquisar',
        searchText: 'Sem registros'
      })
    }
  }

  const addableSlimSelect = (element, placeholder = 'Selecione um valor') => {
    if (!!element) {
      const selector = new slimSelect({
        select: `#${element}`,
        addable: value => value,
        placeholder: placeholder,
        searchPlaceholder: 'Pesquisar',
        searchText: 'Sem registros'
      })

      selector.slim.search.input.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
          selector.slim.search.addable.click()
        }
      })
    }
  }

  window.initSlimSelect = initSlimSelect
  window.addableSlimSelect = addableSlimSelect
})()
