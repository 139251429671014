import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {}

  set_form(event) {
    const form = document.getElementById('form_service_order_parts')
    const formSubmit = document.getElementById('submit_service_order_parts')
    const formMethod = document.getElementById('method_service_order_parts')

    formMethod.value = event.target.dataset.method || 'delete'
    form.action = event.target.dataset.url
    formSubmit.click()
  }
}
