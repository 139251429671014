import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  initialize() {}

  updateReadingsChart(event) {
    const frame = document.getElementById('ts_equipament_readings_chart')
    frame.src = `/equipaments/${event.target.dataset.equipamentId}/update_readings_chart?period=${event.target.value}`
    frame.reload()
  }

  updateStopChart(event) {
    const frame = document.getElementById('ts_equipament_stop_chart')
    frame.src = `/equipaments/${event.target.dataset.equipamentId}/update_stop_chart?period=${event.target.value}`
    frame.reload()
  }

  showFields(event) {
    const frame = document.getElementById('ts_equipament_infos')
    frame.src = `/equipaments/${event.target.dataset.equipament || 0}/infos`
    frame.reload()
  }
}
