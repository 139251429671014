(function () {
  const Nestedjs = function () {
    return {
      addNested: function(id) {
        let content = document.getElementById(id).querySelectorAll('template');
        let template = content[content.length -1];
        let nested = template.innerHTML.replace(/NESTED_INDEX/g, new Date().valueOf());

        template.insertAdjacentHTML('beforebegin', nested);
      },
      removeNested: function() {
        let nested = event.target.closest(".nested-fields");
        nested.querySelector("input[name*='_destroy']").value = 1;
        nested.style.display = 'none';
      },
      removeAllNesteds: function(id) {
        let nesteds = document.getElementById(id).querySelectorAll('.nested-fields');
        for (var nested of nesteds) {
          nested.querySelector("input[name*='_destroy']").value = 1;
          nested.style.display = 'none';
        }
      },
      generateId: function() {
        return setTimeout(new Date().valueOf(), 100);
      },
    };
  }

 if (typeof window !== 'undefined') {
   window.Nestedjs = Nestedjs;
 }

 return Nestedjs;
})();
