import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  update(event) {
    var url = window.location.href.split('?')[0];
    url += `?period=${event.target.value}#tabService_order`

    window.location.href = url;
  }
}
