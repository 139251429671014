import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {}

  change(event) {
    if (event.target.value != 'general') {
      const frame = document.getElementById('ts_service_order_kind')
      frame.src = `/service_orders/equipament_type?type=${event.target.value}`
      frame.reload()
    }
  }

  showFields(event) {
    const frame = document.getElementById('ts_equipament_infos')
    frame.src = `/equipaments/${event.target.value || 0}/infos`
    frame.reload()

    showChecklist()
  }

  showChecklist() {
    const equipamentField = document.getElementById('equipament-field')
    const maintenanceTypeField = document.getElementById(
      'maintenance-type-field'
    )

    if (maintenanceTypeField.value == 'preventive') {
      const frame = document.getElementById('ts_service_order_checklist')
      frame.src = `/service_orders/checklists?id=${maintenanceTypeField.dataset.serviceOrderId}&equipament_id=${equipamentField.value}&maintenance_type=${maintenanceTypeField.value}`
      frame.reload()
    }
  }

  updateFieldProcess(event) {
    const frame = document.getElementById('ts_service_order_process')
    frame.src = `/service_orders/${event.target.dataset.serviceOrderId}/update_field_process?process=${event.target.value}`
    frame.reload()
  }
}
