import { application } from './application'

import ApplicationController from './application_controller'

application.register('application', ApplicationController)

import PopperController from './components/popper_controller'
application.register('popper', PopperController)

import CitySelectController from './components/city_select_controller'
application.register('city_select', CitySelectController)

import DashboardServiceOrdersController from './dashboard/service_orders_controller'
application.register(
  'dashboard-service-orders',
  DashboardServiceOrdersController
)

import ServiceOrdersController from './service_orders/service_orders_controller'
application.register('service-orders', ServiceOrdersController)

import EquipamentsController from './equipaments/equipaments_controller'
application.register('equipaments', EquipamentsController)

import AttachmentsController from './components/attachments_controller'
application.register('attachments', AttachmentsController)

import ServiceOrderPartsController from './components/service_order_parts_controller'
application.register('service-order-parts', ServiceOrderPartsController)

import PartsController from './parts/parts_controller'
application.register('parts', PartsController)

import PlansController from './plans/plans_controller'
application.register('plans', PlansController)
