import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  set_form(event) {
    const form = document.getElementById('form_attachments');
    const formSubmit = document.getElementById('submit_attachments');

    form.action = event.target.dataset.url;
    formSubmit.click()
  }
}
