import { AttachmentUploads } from "./attachmentUploads";

export const hiddenSpinner = () => {
  u('.js-spinner').addClass('hidden');
};

(function() {
  const Attachment = () => {

    const setParams = (self) => {
      formData = new FormData()
      formData.append('attachment[attachable_id]', self.attachableId);
      formData.append('attachment[attachable_type]', self.attachableType);
      formData.append('attachment[name]', self.fileName);
      formData.append('attachment[kind]', self.kind);

      return formData;
    }

    return {
      submitUpload: function (path) {
        let params = setParams(this);
        return AttachmentUploads().uploadDocument(path, params, this);
      }
    }
  }

  if (typeof window !== "undefined") {
    window.Attachment = Attachment;
  }

  return Attachment;
})();
