import "@hotwired/turbo-rails"
import "./controllers"
import "./config/stimulus_reflex"

import 'inputmask/dist/inputmask';

import * as Gridjs from "gridjs";
import Alpine from "alpinejs";

import { Turbo } from "@hotwired/turbo-rails"

// AlpineJS Plugins
import persist from "@alpinejs/persist"; // @see https://alpinejs.dev/plugins/persist
import collapse from "@alpinejs/collapse"; // @see https://alpinejs.dev/plugins/collapse
import intersect from "@alpinejs/intersect"; // @see https://alpinejs.dev/plugins/intersect
import mask from '@alpinejs/mask'

// Alpine Components
import usePopper from "./components/usePopper";
import accordionItem from "./components/accordionItem";

import SimpleBar from "simplebar";

import breakpoints from "./utils/breakpoints";
import * as helpers from "./utils/helpers";
import * as masks from "./utils/masks.js";

import Sortable from "sortablejs";

import * as FilePond from "filepond"; // @see https://pqina.nl/filepond/
import FilePondPluginImagePreview from "filepond-plugin-image-preview"; // @see https://pqina.nl/filepond/docs/api/plugins/image-preview/
import pt_BR from 'filepond/locale/pt-br.js';

// Global Store
import store from "./store";

import "./components/nestedJs";
import "./components/mask";
import "./components/sweetAlert";

require('./packs/sortable')
require('./packs/slim_select')
require('./packs/flatpickr')

require('./attachments/attachment');
require('./attachments/attachmentUploads');

var u = require('umbrellajs');
window.u = u;

FilePond.setOptions(pt_BR);
FilePond.registerPlugin(FilePondPluginImagePreview);

window.FilePond = FilePond;
window.SimpleBar = SimpleBar;
window.Sortable = Sortable;
window.Gridjs = Gridjs;

window.Alpine = Alpine;
window.helpers = helpers;
window.masks = masks;

Alpine.plugin(persist);
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(mask);

Alpine.data("usePopper", usePopper);
Alpine.data("accordionItem", accordionItem);

Alpine.store("breakpoints", breakpoints);
Alpine.store("global", store);

window.addEventListener("DOMContentLoaded", () => {
  Alpine.start()
  Turbo.setConfirmMethod(confirmModal);
});

window.addEventListener("turbo:load", (event) => {
  Turbo.cache.clear()

  setTimeout(() => {
    document.body.classList.remove("is-sidebar-open-static");
  }, 250);
});

const hideFlash = (el, now = false) => {
  if (now) {
    el.classList.toggle('!hidden')
  } else {
    setTimeout(function () {
      el.classList.toggle('!hidden')
    }, 5000)
  }
}

window.hideFlash = hideFlash;
