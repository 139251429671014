import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {}

  change(event) {
    const selectedState = event.target.value
    const frame = document.getElementById('ts_city_select')

    if (selectedState) {
      frame.src = `/people/cities?state=${selectedState}`
    } else {
      frame.src = 'about:blank'
      clearCityOptions()
    }

    frame.reload()
  }
}

function clearCityOptions() {
  const citySelect = document.getElementById('person_address_attributes_city')
  const defaultOption = document.createElement('option')
  defaultOption.value = ''
  defaultOption.text = 'Selecione uma cidade'
  citySelect.innerHTML = ''
  citySelect.appendChild(defaultOption)
}
