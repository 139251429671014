import Sortable from "sortablejs";
import Rails from "@rails/ujs";

(function () {
  const initSortable = () => {
    elements = document.querySelectorAll(".kanban-col");
    if (!!elements) {
      arrowElements = document.querySelectorAll(".arrow-kanban");
      elements.forEach((ul) => {
        Sortable.create(ul,{
          animation: 200,
          group:'board-cards',
          easing: 'cubic-bezier(0, 0, 0.2, 1)',
          direction: 'vertical',
          delay: 150,
          scrollSensitivity: 200,
          delayOnTouchOnly: true,
          bubbleScroll: true,
          onStart: function(e){
            arrowElements.forEach((e) => e.classList.toggle('hidden'))
            arrowElements.forEach((e) => e.classList.toggle('flex'))
          },
          onEnd: function(e){
            arrowElements.forEach((e) => e.classList.toggle('hidden'))
            arrowElements.forEach((e) => e.classList.toggle('flex'))

            const toColor = e.to.dataset.colColor
            // e.item.querySelector('.bg-so-status-color').style.background = `rgba(${toColor}, 0.02)`;
            setTimeout(() => {
              e.item.querySelector('.text-so-status-color').style.background = `rgba(${toColor}, 0.1)`;;
            }, 1500);

            saveKanban(e.item.dataset.itemId, e.to.dataset.colId)
          }
        })
      });

    }
  }

  const saveKanban = (serviceOrder, status) => {
    const formData = new FormData();
    formData.append('service_order[order_service_status_id]', status);
    formData.append('by_panel', status);
    Rails.ajax({
      url: `/service_orders/${serviceOrder}`,
      header: { "Accept": "text/vnd.turbo-stream.html" },
      type: "patch",
      data: formData,
      success: function(response){
        Turbo.renderStreamMessage(response)
      },
      error: function(response){
        location.reload()
      }
    })
  }

  window.initSortable = initSortable;
})();
