import Swal from 'sweetalert2'
;(function () {
  const swal = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-lg btn-primary',
      cancelButton: 'btn btn-lg mr-2 btn-danger'
    },
    title: 'Você tem certeza?',
    text: 'Esta ação é irreversível.',
    confirmButtonText: 'Sim, tenho certeza!',
    cancelButtonText: 'Não, quero cancelar!',
    showCancelButton: true,
    buttonsStyling: false,
    reverseButtons: true
  })

  const formattDeleteTurboParams = form => {
    let hash = {}
    formParams = new FormData(form)

    for (let [key, value] of formParams) {
      hash[key] = value
    }

    return hash
  }

  const turboDelete = (element, method) => {
    const token = u('meta[name="csrf-token"]').attr('content')
    const params = formattDeleteTurboParams(element.closest('form'))

    let fetchConfig = {
      method: method.toUpperCase(),
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }

    fetch(element.action, fetchConfig)
      .then(response => {
        if (!response.ok) {
          return location.reload()
        }

        return response.text()
      })
      .then(turboHtml => Turbo.renderStreamMessage(turboHtml))
  }

  const confirmModal = (modalParams, element) => {
    let parsed

    try {
      parsed = JSON.parse(modalParams)
    } catch {
      parsed = modalParams
    }

    swal.fire(parsed).then(result => {
      if (result.isConfirmed) {
        let requestMethod = u(element).children('button').data('turbo-method')
        requestMethod ||= u(element)
          .children('input[name=_method]')
          .attr('value')

        if (
          (requestMethod == 'delete' || !!element.dataset.turbo) &&
          !element.dataset.redirect
        ) {
          return turboDelete(element, requestMethod)
        }

        element.submit()
      }
    })
  }

  if (typeof window !== 'undefined') {
    window.swal = swal
    window.confirmModal = confirmModal
  }

  return swal
})()
