import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {}

  showFields(event) {
    const frame = document.getElementById('ts_plan_infos')
    frame.src = `/plans/${event.target.dataset.plan || 0}/infos`
    frame.reload()
  }
}
