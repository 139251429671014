import flatpickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

(function () {
  function setClearToCalendar(calendar, instance) {
    calendar.append('<div class="flatpickr-clear">Limpar</div>')

    calendar.find('.flatpickr-clear').on('click', function () {
      instance.clear()
      instance.close()
    })
  }

  const init = () => {
    flatpickr('.date-flatpickr', {
      locale: Portuguese,
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: 'd/m/Y',
      onReady: function (_dateObj, _dateStr, instance) {
        let calendar = u(instance.calendarContainer)

        if (calendar.find('.flatpickr-clear').length < 1) {
          setClearToCalendar(calendar, instance)
        }
      }
    })

    flatpickr('.time-flatpickr', {
      locale: Portuguese,
      dateFormat: 'Y-m-d H:i',
      altInput: true,
      enableTime: true,
      altFormat: 'd/m/Y H:i',
      onReady: function (_dateObj, _dateStr, instance) {
        let calendar = u(instance.calendarContainer)

        if (calendar.find('.flatpickr-clear').length < 1) {
          setClearToCalendar(calendar, instance)
        }
      }
    })
  }

  const FlatPickr = { init }

  if (typeof window !== 'undefined') {
    window.FlatPickr = FlatPickr
  }

  return FlatPickr
})()
