export const appendAuthenticityTokenToUrl = function (href) {
  const authenticityToken = document
    .getElementsByName("csrf-token")[0]
    .getAttribute("content");
  const character = href.includes("?") ? "&" : "?";

  return `${href}${character}authenticity_token=${authenticityToken}`;
};

export const StartUpload = (file, saveFile, controllerParams) => {
  const progressBarDiv = ".progress-bar-div";
  const progressBar = ".progress-bar";
  const btnSave = "#btn_save";
  const start = 0;

  const bucket = new window.AWS.S3({
    accessKeyId: gon.aws_key_id,
    secretAccessKey: gon.aws_secret_key,
    region: gon.aws_region,
  });

  const setProgress = (progressPercentage) => {
    u(progressBarDiv).removeClass("hidden");
    u(progressBar).attr("style", `width: ${progressPercentage}%`);
    u(progressBar).text(`${progressPercentage}%`);
  };

  const uploadFile = (file, saveFile) => {
    const params = {
      Bucket: gon.aws_bucket_attachments,
      Key: Date.now() + file.name,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        return false;
      }

      saveFile(controllerParams, data.Location);
      return true;
    });
  };

  return {
    init: function () {
      u(btnSave).addClass("btn-disabled");
      setProgress(start);

      uploadFile(file, saveFile).on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );

        setProgress(progressPercentage);
      });
    },
  };
};
