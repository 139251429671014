import { appendAuthenticityTokenToUrl, StartUpload } from "../baseUpload";

let uploadPath = '';
let contextOfAttachment = {};

export const AttachmentUploads = () => {
  const saveFile = (params = [], url) => {
    const headers = {
      "Accept": "text/vnd.turbo-stream.html"
    };

    params.append('attachment[s3_url]', url);

    submitRequest(headers, params);
  };

  const submitRequest = (headers, params) => {
    const href = appendAuthenticityTokenToUrl(uploadPath);

    fetch(href, {
      method: "POST",
      headers: headers,
      body: params,
    }).then(r => r.text())
      .then((html) =>  {
        Turbo.renderStreamMessage(html);
      });
  };

  return {
    uploadDocument: function(path, params, attachmentContext) {
      let attachment = document.getElementById('attachment_document').files[0];
      uploadPath = path;
      contextOfAttachment = attachmentContext;

      StartUpload(attachment, saveFile, params, contextOfAttachment).init();
    }
  }
};
